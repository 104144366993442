






















































































import Vue from "vue";
import { formatDollars } from '../common'

export default Vue.extend({
  name: "PatientEstimate",
  props: {
    benefits: {
      type: Object,
      default: null,
    },
    allowed_amount: {
      type: Number,
      default: null,
    },
    charged_amount: {
      type: Number,
      default: null,
    },
  },

  computed: {
    dataAllowed(): number {
      return this.allowed_amount ?? 0;
    },
    dataCharged(): number {
      return this.charged_amount ?? 0;
    },
    dataCopay(): number {
      return parseInt(this.benefits?.copay) ?? 0;
    },
    dataDeductible(): number {
      return parseInt(this.benefits?.deductible) ?? 0;
    },
    dataOOP(): number {
      return parseInt(this.benefits?.out_of_pocket) ?? 0;
    },
    dataCoInsurance(): number {
      return parseInt(this.benefits?.patient_responsibility) ?? 0;
    },
    dataResponsibilityType(): number {
      return parseInt(this.benefits?.patient_responsibility_type) ?? 0;
    },
    patient_calculation(): number {
      if (this.dataAllowed <= this.dataDeductible) {
        return this.dataCopay + this.dataAllowed;
      }

      const patient_responsibility_over_deductible =
        Math.min(this.dataOOP, this.dataAllowed) - this.dataDeductible;
      const patient_coinsurance_due =
        patient_responsibility_over_deductible * (this.dataCoInsurance / 100);

      return this.dataCopay + this.dataDeductible + patient_coinsurance_due;
    },

    estimated_patient_charge(): string {
      if (Number.isNaN(this.patient_calculation)) {
        return "Cannot calculate";
      }
      return formatDollars(this.patient_calculation);
    },
  },
});
