<template>
<b-modal id="new-insurance" @hide="resetModal" title="New Patient Insurance" hide-footer size="xl">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div class="px-3 pb-3">
        <b-row class="">
            <b-col>
                <label class="selecter-label">Payer</label>
                <b-form-group>
                    <b-input-group>
                        <multiselect
                            openDirection="bottom"
                            placeholder="Search by payer name or address" 
                            label="payer_name"
                            :custom-label="payerLabel"
                            v-model="selectedPayer"
                            track-by="payer_id"
                            :options="selectablePayers"
                            :loading="payersLoading"
                            :internal-search="false"
                            :show-labels="false"
                            @search-change="debouncePayers"
                            :showNoOptions="true"
                            :allow-empty="false">
                            <template slot="noResult">
                                No payers matched your search
                            </template>
                            <template slot="noOptions">
                                Start typing a payer name or address...
                            </template>
                        </multiselect>
                    </b-input-group>
                    </b-form-group>
            </b-col>
            
        </b-row>
        <b-row>
            <b-col>
                <label class="selecter-label">Member ID</label>
                <b-form-input v-model="insurance.member_id"></b-form-input>
            </b-col>
            <b-col>
                <label class="selecter-label">Group No.</label>
                <b-form-input v-model="insurance.group_no"></b-form-input>
            </b-col>
            <b-col cols="6">
                <b-row>
                    <b-col>
                        <label class="selecter-label">Effective Dates</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-input type="date" v-model="insurance.effective_start_date" 
                        :placeholder="insurance.effective_start_date || ''" 
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="en"
                        ></b-form-input>
                    </b-col>
                <b-col cols="1.5" class="selecter-label mt-0" style="padding-top:.75rem;">
                    to
                </b-col>
                <b-col>
                    <b-form-input type="date" v-model="insurance.effective_end_date" 
                    :placeholder="insurance.effective_end_date || ''"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    ></b-form-input>
                </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="">
            <b-col cols="4">
                <label class="selecter-label">Subscriber's name</label>
                <b-form-input v-model="insurance.subscriber_name"></b-form-input>
            </b-col>
            <b-col cols="4">
                <label class="selecter-label">Relationship to subscriber</label>
                <b-form-radio-group 
                v-model="insurance.subscriber_relationship"
                style="margin-top:10px;">
                    <b-form-radio value="0">Self</b-form-radio>
                    <b-form-radio value="1">Spouse</b-form-radio>
                    <b-form-radio value="2">Child</b-form-radio>
                    <b-form-radio value="3">Other</b-form-radio>
                </b-form-radio-group>
            </b-col>
            <b-col cols="1">
                <label class="selecter-label">P/S/T</label>
                    <b-form-select
                        v-model="insurance.insurance_primacy"
                        :options="[{text: 'P', value: 'PRIMARY'}, {text: 'S', value: 'SECONDARY'}, {text: 'T', value: 'TERTIARY'}, {text: 'U', value: 'UNKNOWN'}]"
                        >/</b-form-select>
            </b-col>
            <b-col cols="2" class="mt-auto">
                <b-form-checkbox v-model="insurance.active_benefits" switch>
                    <div class="selecter-label mt-0">Verified active</div>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row class="" v-if="insurance.insurance_primacy === 'PRIMARY'">
            <b-col>
                <label class="selecter-label">Co-pay</label>
                <b-input-group prepend="$">
                <b-form-input 
                v-model="insurance.copay"
                type="number"
                ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <label class="selecter-label">Deductible not met</label>
                <b-input-group prepend="$">
                <b-form-input 
                v-model="insurance.deductible"
                type="number"
                ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <label class="selecter-label">OOP not met</label>
                <b-input-group prepend="$">
                <b-form-input 
                v-model="insurance.out_of_pocket"
                type="number"
                ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <label class="selecter-label">Coinsurance</label>
                <b-input-group append="%">
                <b-form-input 
                v-model="insurance.patient_responsibility"
                type="number"
                ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col class="mt-auto" cols="3">
                    <b-form-radio-group
                    v-model="insurance.patient_responsibility_type"
                    class="mb-2">
                        <b-form-radio value="0"><span class="selecter-label">Deductible</span></b-form-radio>
                        <b-form-radio value="1"><span class="selecter-label">OOP</span></b-form-radio>
                    </b-form-radio-group>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col class="text-right">
                <b-button @click="onSubmit" class="avo-primary-btn">
                    Add insurance
                </b-button>
            </b-col>
        </b-row>
    </div>
</b-form>
</b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request, debounce, alertsMixin } from '../../common.js'

export default {
  name: 'InsuranceAdd',
  props: ['patientId', 'currentInsurances'],
  components: {
      Multiselect
  },
  mixins: [alertsMixin],
  data: function(){
      return {
            searchText: "",
            payersLoading: false,
            selectablePayers: [],
            selectedPayer: null,

            payer_id: null,
            insurance: {
                member_id: null,
                group_no: null,
                effective_start_date: null,
                effective_end_date: null,
                subscriber_name: null,
                subscriber_relationship: -1,
                active_benefits: false,
                insurance_primacy: 'UNKNOWN',
                copay: null,
                deductible: null,
                out_of_pocket: null,
                patient_responsibility: null,
                patient_responsibility_type: -1,
            },
            
            payers: [],

            debouncePayers: null,
      }
  },
  methods: {
    clearData(){
        this.insurance = {
            member_id: null,
            group_no: null,
            effective_start_date: null,
            effective_end_date: null,
            subscriber_name: null,
            subscriber_relationship: -1,
            active_benefits: false,
            insurance_primacy: 'UNKNOWN',
            copay: null,
            deductible: null,
            out_of_pocket: null,
            patient_responsibility: null,
            patient_responsibility_type: -1,
        };
        this.searchText = "",
        this.payersLoading = false,
        this.selectablePayers = [],
        this.selectedPayer = null,

        this.payer_id = null;
        this.hideError();
    },
    payerSearchChange: function(searchQuery) {
        this.payersLoading = true;
        this.searchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.payerAutocomplete();
        } else {
            this.payersLoading = false;
        }
        
    },
    payerAutocomplete() {
        var vm = this;
        const url = make_url("/reports/payers/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.searchText,
                limit: 10
            }
        }).then(function(response){
            vm.payersLoading = false;
            const payers = response.data.payers;
            vm.selectablePayers = payers || [];
            return payers || [];
        }).catch(function(errors){
            console.log(errors.response)
            vm.showError(errors);
        });
    },
    payerLabel(payer){
        if (payer.full_address){
            return (payer.name + ' - ' + payer.full_address)
        } else {
            return payer.name
        }
    },
    validateInsuranceInputs(){
        this.hideError();
        
        if (this.patientId == null){
            this.showError("Unable to find patient id");
            return false;
        }
        if (this.payer_id == null){
            this.showError("Please select a payer");
            return false;
        }
        for (var i = 0; i < this.currentInsurances.length; i++){
            if (this.payer_id == this.currentInsurances[i].payer_id){
                this.showError("Patient is already associated with this payer");
                return false;
            }
        }
        return true;
    },
    create_patient_payer(){
        var data = {};
        for (let key in this.insurance){
            if ((this.insurance[key] != null) && (this.insurance[key] != "")){
                if ((key != "effective_start_date") && (key != "effective_end_date")){
                    data[key] = this.insurance[key];
                }
                else{
                    data[key] = this.insurance[key] + "T00:00:00+00:00";
                }
            }
        }
        const url = make_url("/patients/" + this.patientId + "/payers/" + this.payer_id);
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: data
        }).then(function(response){
            if (response){
                self.$bvModal.hide('new-insurance');
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInsuranceInputs()){
            this.create_patient_payer();
        }
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  watch: {
      selectedPayer(newValue){
          if(newValue){
              this.payer_id = newValue.payer_id;
          } else {
              this.payer_id = null;
          }
      }
  },
  created(){
      this.debouncePayers = debounce(this.payerSearchChange, 300)
  },
}
</script>

<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5;
}

</style>