


































































































































import Vue from 'vue'
import { make_url, authenticated_request, formatDateShort, alertsMixin } from '../../common.js'
import InsuranceAdd from '../payers/InsuranceAdd.vue'
import InsuranceEdit from '../payers/InsuranceEdit.vue'

type Insurance = {
    payer_id: string,
    name: string,
    effective_start_date: string,
    effective_end_date: string,
    active_benefits: boolean,
    full_address: string,
    member_id: string,
    group_no: string,
    subscriber_name: string,
    subscriber_relationship: number,
    telephone: string,
    fax: string,
    active: boolean,
    primary_insurance: boolean,
    patient_id: number,
    insurance_primacy: string,
    deductible: number,
    copay: number,
    coinsurance: number,
    max_out_of_pocket: number,
    zip: string,
}

export default Vue.extend({
    name: 'InsuranceList',
    props: {
        patientId: Number, 
        patientName: String,
        tableStyle: {
            type: String,
            default: 'border:solid 1px #E2E4EB;font-size:14px;',
        },
        noDataMessage: {
            type: String,
            default: 'No insurances found',
        },
        tableClass: {
            type: String,
            default: 'shadow',
        },
        borderless: {
            type: Boolean,
            default: false,
        },
        insurancesLimit: {
            type: Number || undefined,
            default: -1, 
        },
    },
    components: {
        InsuranceAdd,
        InsuranceEdit
    },
    mixins: [
        alertsMixin,
    ],
    created(){
        if (this.patientId){
            this.getPatientInsurances();
        }
        else{
            this.insurancesLoading = false;
        }
    },
    data() {
        return {
            insurances: [] as Insurance[],
            insurance_to_edit: null as Insurance | null,
            
            insurancesLoading: true,
        }
    },
    methods: {
        getPatientInsurances: function() {
            const url = make_url("/patients/reports/" + this.patientId + "/insurance");
            const self = this;
            const promise: Promise<Insurance[]> = authenticated_request({
                method: "get",
                url: url
            }).then(function(response: any){
                const insurances = response.data.insurance;
                self.hideError()
                self.insurances = insurances;
                self.$emit("insurancesUpdated", insurances);

                return insurances;
            }).catch(function(errors){
                self.showError(errors);
            }).finally(()=>{
                self.insurancesLoading = false;
            });
            
            return promise;
        },
        insuranceStatus: function(insurance: Insurance) {
            if (!insurance) {
                return "Unknown";
            }
            const isDateAfterToday = function(date: string) {
                if (date == null) {
                    return false;
                }
                const today = new Date();
                const dateToCheck = new Date(date);
                return dateToCheck > today;
            };
            if (isDateAfterToday(insurance.effective_start_date) 
                    || !isDateAfterToday(insurance.effective_end_date)) {
                return "Not effective";
            }
            else if (insurance.effective_start_date || insurance.effective_end_date) {
                return "Effective";
            }
            else {
                return "Unknown";
            }
        },
        subscriberRelationship: function(relationship: number | null){
            switch(relationship){
                case 0:
                    return "Self"
                case 1:
                    return "Spouse"
                case 2:
                    return "Child"
                case 3:
                    return "Other"
                default:
                    return "Unknown"
            }
        },
        dateFormat(date: string){
            if (date){
                return formatDateShort(date)
            }
            return "N/A";
        },
        formatInsurancePrimacy(insurance: Insurance){
            if (insurance.primary_insurance){
                return "P";
            } 
            else if (insurance.insurance_primacy === null || insurance.insurance_primacy === undefined) {
                return "U";
            } 
            else {
                return insurance.insurance_primacy.charAt(0).toUpperCase();
            }
        },
        isPrimary(insurance: any){
            return this.formatInsurancePrimacy(insurance) == 'P';
        },
        deleteInsurance(insurance: Insurance){
            if (!insurance) {
                return;
            }
            const url = make_url("/patients/" + this.patientId + "/payers/" + insurance['payer_id']).toString();
            const self = this;
            const promise = authenticated_request({
                method: "delete",
                url: url
            }).then(function(response){
                if (response){
                    self.insurances.splice(self.insurances.indexOf(insurance), 1);
                    self.$emit("insurancesUpdated", self.insurances);
                }
            }).catch(function(errors){
                self.showError(errors);
            });
            
            return promise;
        },
        showError(alertMessage: string){
            (this as any).setShowAlert(true, alertMessage);
        },
        hideError(){
            (this as any).setShowAlert(false);
        },
    },
    computed: {
        orderedInsurances: function(): Insurance[] {
            let rank = (insurance: Insurance) => 'PSTU'.indexOf(this.formatInsurancePrimacy(insurance));
            const sortedInsurances = [...this.insurances].sort((a, b) => rank(a) - rank(b));
            if (this.insurancesLimit > 0){
                return sortedInsurances.slice(0, this.insurancesLimit);
            } else {
                return sortedInsurances;
            }
        },
    },
    watch: {
        patientId: function(){
            if (this.patientId){
                this.getPatientInsurances();
            }
        },
    }
})
