<template>
<div>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-overlay :show="codesLoading" variant="white">
    <b-table-simple :outlined="outlined" :borderless="borderless" :class="tableClass" :style="tableStyle">
        <b-thead>
            <b-tr>
                <b-th>
                    CPT/HCPCS
                </b-th>
                <b-th>
                    Modifiers
                </b-th>
                <b-th v-if="claimId">
                    Description
                </b-th>
                <b-th class="text-right">
                    Unit Charge 
                </b-th>
                <b-th v-if="preauthId || estimate" class="text-right">
                    Unit Allowed 
                </b-th>
                <b-th v-if="preauthId">
                    State
                </b-th>
                <b-th>
                    Units
                </b-th>
                <b-th v-if="claimId">
                    Pointers
                </b-th>
                <b-th v-if="claimId">
                    POS
                </b-th>
                <b-th v-if="claimId">
                    TOS
                </b-th>
                <b-th class="text-right">
                    Charge
                </b-th>
                <b-th>
                </b-th>
            </b-tr>
        </b-thead>
        <b-tbody v-if="codes.length > 0">
            <b-tr v-for="(code, index) in codes" :key="'code' + index">
                <b-td>
                    <div class="mt-2">
                        {{code.code}}: <span class="avo-text-light-small">{{(code.short || '-')}}</span>
                    </div>
                </b-td>
                <b-td>
                    <div v-if="code.modifiers" class="data-text-large">
                        <b-badge v-for="(modifier, modIdx) in code.modifiers" 
                            :key="'code: ' + index + 'modifier:' + modIdx"
                            variant="light"
                            class="mr-2 p-2 text-primary border border-primary">
                                {{modifier}}
                        </b-badge>
                    </div>
                </b-td>
                <b-td v-if="claimId" style="max-width:425px;width:425px;overflow-wrap:break-word">
                    <span class="avo-text-light-small">{{code.medium || code.long || code.short || "-"}}</span>
                </b-td>
                <b-td>
                    <div class="mt-2 text-right">
                        ${{Number(code.unit_charge).toFixed(2)}}
                    </div>
                </b-td>
                <b-td v-if="preauthId || estimate">
                    <div class="mt-2 text-right" :style='code.state === "REJECTED" ? "text-decoration: line-through;": ""'>
                        {{code.allowed_amount ? '$' + Number(code.allowed_amount).toFixed(2) : '--'}}
                    </div>
                </b-td>
                <b-td v-if="preauthId">
                    <div class="mt-2">
                        {{renderPreauthCodeState(code.state)}}
                    </div>
                </b-td>
                <b-td>
                    <div class="mt-2">
                        {{code.units || 0}}
                    </div>
                </b-td>
                <b-td v-if="claimId">
                    <div v-if="code.pointers" class="data-text-large">
                        <b-badge 
                            v-for="(pointer, ptrIdx) in code.pointers" 
                            :key="'code: ' + index + 'pointer:' + ptrIdx" 
                            variant="light"
                            class="mr-2 p-2 text-primary border border-primary">
                           {{pointer.name}} 
                        </b-badge>
                    </div>
                </b-td>
                <b-td v-if="claimId">
                    <div class="mt-2">
                        {{code.pos || '-'}}
                    </div>
                </b-td>
                <b-td v-if="claimId">
                    <div class="mt-2">
                        {{code.tos || '-'}}
                    </div>
                </b-td>
                <b-td>
                    <div class="mt-2 text-right">
                        ${{Number(((code.unit_charge || 0) * (code.units || 0))).toFixed(2)}}
                    </div>
                </b-td>
                <b-th>
                    <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                        <template #button-content>
                        <b-icon icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item @click="viewEditModal(index)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="deleteCode(index)">Remove</b-dropdown-item>
                        <b-modal :id="'edit-cpt-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text" style="text-align:center">
                                Edit CPT Code
                            </div>
                            <hr>
                            <b-alert
                                v-model="showAlertModal"
                                variant="danger"
                                dismissible>
                                <b>{{errorTextModal}}</b>
                            </b-alert> 
                            <b-row>
                                <b-col>
                                    <b-form-group
                                    id="units"
                                    label="Units:">
                                    <b-input-group>
                                        <b-form-input type="number" v-model="editData['units']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group
                                    id="unit-charge"
                                    label="Unit charge:">
                                    <b-input-group prepend="$">
                                        <b-form-input type="number" v-model="editData['unit_charge']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="estimate">
                                <b-col>
                                </b-col>
                                <b-col>
                                    <b-form-group
                                    id="allowed-amount"
                                    label="Allowed amount:">
                                    <b-input-group prepend="$">
                                        <b-form-input type="number" v-model="editData['allowed_amount']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="preauthId">
                                <b-col>
                                </b-col>
                                <b-col>
                                    <b-form-group
                                    id="state"
                                    label="Preauth state:">
                                    <b-form-select
                                        v-model="editData['state']"
                                        :options="[{text: 'Initial', value: 'INITIAL'}, 
                                                    {text: 'Authorized', value: 'AUTHORIZED'}, 
                                                    {text: 'Denied', value: 'REJECTED'}, 
                                                    {text: 'No auth needed', value: 'NOAUTHNEEDED'}]"
                                        >/</b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="mb-3">
                                <b-col>
                                    <span class="avo-text-light-small">
                                        <i class="fas fa-info-circle mr-2"/>
                                        Code and modifier fields cannot be changed once set, 
                                        to add another code with different modifiers, use the 
                                        "Add CPT code" button.</span>
                                </b-col>
                            </b-row>
                            <b-row v-if="!estimate">
                                <b-col>
                                    <b-form-group
                                    id="pos"
                                    label="POS:">
                                    <b-input-group>
                                        <b-form-input type="number" v-model="editData['pos']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group
                                    id="tos"
                                    label="TOS:">
                                    <b-input-group>
                                        <b-form-input type="number" v-model="editData['tos']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="!estimate">
                                <b-col>
                                    <b-form-group
                                    id="pointers"
                                    label="Pointers:">
                                        <multiselect 
                                            v-model="editData['pointers']"
                                            :hide-selected="true"
                                            :options="pointers"
                                            :multiple="true"
                                            :show-labels="false"
                                            :searchable="false"
                                            label="name"
                                            track-by="value"
                                            placeholder="-- Select pointers --">
                                        </multiselect>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col style="text-align:right">
                                    <b-button @click="closeEditModal(index)" class="avo-basic-btn mr-2">
                                        Cancel
                                    </b-button>
                                    <b-button @click="submitEdit(index)" class="avo-primary-btn">
                                        Submit CPT Code Changes
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-modal>
                    </b-dropdown>
                </b-th>
            </b-tr>
        </b-tbody>
        <b-tbody v-else>
            <div class="no-data-text">
                {{ noDataMessage }}
            </div>
        </b-tbody>
        <b-tfoot>
                <b-tr>
                <b-td colspan="12">
                <div class="d-flex justify-content-between">
                    <div>
                        <b-button 
                            v-b-modal.new-cpt-code 
                            class="avo-primary-btn mr-2" 
                            style="width:150px;height:30px">
                            <b-icon class="mr-2" icon="plus-circle"></b-icon>
                            Add CPT code
                        </b-button>
                        <cpt-code-add 
                            :practice-id="practiceId"
                            :estimate="estimate"
                            @add-cpt-code="addCode($event)"
                            :preauth-id="preauthId" 
                            :claim-id="claimId" 
                            :current-codes="codes" 
                            :selectablePointers="pointers" 
                            @submitted="handleCptCodeAddSubmitted()" />
                    </div>
                    <div style="margin-top:10px">
                        <span v-if="preauthId || estimate">
                            <span class="mr-3">
                                Total allowed:
                            </span>
                            <span class="mr-3 bold">
                                ${{totalAllowed}}
                            </span>
                        </span>
                            <span class="mr-3">
                                Total charged:
                            </span>
                            <span class="bold">
                                ${{totalCharged}}
                            </span>
                    </div>
                </div>
                </b-td>
                </b-tr>
                <b-tr v-if="patientInsuranceBenefits">
                <b-td colspan="12">
                <div class="d-flex justify-content-end">
                    <span>
                        <patient-estimate
                            :benefits="patientInsuranceBenefits"
                            :allowed_amount="totalAllowedNumber"
                            :charged_amount="totalChargedNumber"
                            >
                            <!-- <template v-slot="slotProps">
                                <span class="mr-3">
                                    Patient estimate:
                                </span>
                                <span class="mr-3 bold">
                                    ${{slotProps.estimate}}
                                </span>
                            </template> -->
                        </patient-estimate> 
                    </span>
                </div>
                </b-td>
                </b-tr>
        </b-tfoot>
    </b-table-simple>
    </b-overlay>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import CptCodeAdd from '../codes/CptCodeAdd'
import Multiselect from 'vue-multiselect'
import PatientEstimate from '../PatientEstimate.vue'

export default {
    name: 'CptCodesList',
    props: {
        estimate: {
            type: Boolean,
            default: false
        },
        claimId: {
            type: Number,
            default: null,
        },
        practiceId: {
            type: Number,
            default: null,
        },
        preauthId: {
            type: Number,
            default: null,
        },
        payerId: {
            type: Number,
            default: null,
        },
        tableClass: {
            type: String,
            default: "shadow"
        },
        tableStyle: {
            type: String,
            default: "border:solid 1px #E2E4EB;font-size:14px;",
        },
        noDataMessage: {
            type: String,
            default: "No CPT codes to display."
        },
        borderless: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        patientInsuranceBenefits: {
            type: Object,
            default: null,
        }
    },
    mixins: [alertsMixin],
    components: {
        CptCodeAdd,
        Multiselect,
        PatientEstimate,
    },
    data() {
        return {
            codes: [],
            pointers: [],
            editData: {},

            codesLoading: true,
            showAlertModal: false,
            errorTextModal: "",
        }
    },
    methods: {
        getCodes: function(){
            if (this.estimate) {
                this.codesLoading = false;
                return;
            }
            let url;
            if (this.claimId !== null) {
                url = make_url("/reports/claims/" + this.claimId + "/cpt_codes");
            } else if (this.preauthId !== null) {
                //TODO: need to set up a reports endpoint for preauth cpt_codes
                url = make_url("/reports/preauths/" + this.preauthId + "/cpt_codes");
            }
            let request;
            if (this.payerId) {
                const params = {
                    payer_id: this.payerId,
                }
                request = authenticated_request({
                    method: "get",
                    url: url,
                    params: params, 
                });
            } else {
                console.log("No payer id");
                request = authenticated_request({
                    method: "get",
                    url: url,
                });
            }
            const self = this;
            const promise = request.then(function(response){
                if (self.claimId !== null) {
                    self.codes = response.data.codes || [];
                    self.pointers = response.data.pointers || [];
                    self.responsibilityMap = response.data.responsibility_map;
                }
                else if (self.preauthId !== null) {
                    self.codes = response.data.codes || [];
                    self.pointers = response.data.pointers || [];
                }
                self.setShowAlert(false);
            }).catch(function(error){
                self.setShowAlert(true, error);
            }).finally(()=>{
                self.codesLoading = false;
            });
            return promise;
        },
        addCode: function(code){
            this.codes.push(code);
            this.codesLoading = false;
        },
        deleteCode: function(index){
            if (this.estimate) {
                this.codes.splice(index, 1);
                return;
            }
            let url;
            if (this.claimId !== null) {
                url = make_url("/claims/" + this.claimId + "/cpt_codes/" + this.codes[index]["code"] + "/" + this.codes[index]["claim_code_id"]);
            } else if (this.preauthId !== null) {
                url = make_url("/preauths/" + this.preauthId + "/cpt_codes/" + this.codes[index]["code"] + "/" + this.codes[index]["preauth_code_id"]);
            }
            
            const self = this;
            authenticated_request({
                method: "delete",
                url: url
            }).then(function(){
                self.setShowAlert(false);
                self.$store.commit("setUpdateBilling", true);
                self.getCodes();
            }).catch(function(error){
                self.setShowAlert(true, error);
            });
        },
        createPointersString(pointer_array){
            if (pointer_array && (pointer_array.length > 0)){
                return pointer_array.map(pointer => pointer.name).join(", "); 
            }
            return "-"
        },
        renderPreauthCodeState(state){
            if (state === "INITIAL") {
                return "Initial";
            } else if (state === "AUTHORIZED") {
                return "Authorized";
            } else if (state === "REJECTED") {
                return "Denied";
            } else if (state === "APPROVED") {
                return "Approved";
            } else if (state === "NOAUTHNEEDED") {
                return "No auth needed";
            } else {
                return "n/a";
            }
        },
        viewEditModal(index){
            this.editData = {
                units: (this.codes[index].units || 0),
                unit_charge: (this.codes[index].unit_charge || 0),
                allowed_amount: (this.codes[index].allowed_amount || 0),
                pos: (this.codes[index].pos || 0),
                tos: (this.codes[index].tos || 0),
                state: (this.codes[index].state || -1),
                pointers: (this.codes[index].pointers || []),
            }
            this.$bvModal.show('edit-cpt-modal-'+index);
        },
        closeEditModal(index){
            this.editData = {}
            this.errorTextModal = "";
            this.showAlertModal = false;
            this.$bvModal.hide('edit-cpt-modal-'+index);
        },
        submitEdit(index){
            var data = Object.assign({}, this.editData);
            if (data.pointers && data.pointers.length > 0){
                data.pointers = data.pointers.map(pointer => pointer.value);
            }
            let url;
            if (this.claimId !== null) {
                url = make_url("/claims/" + this.claimId + "/cpt_codes/" + this.codes[index]["code"] + "/" + this.codes[index]["claim_code_id"]);
            } else if (this.preauthId !== null) {
                url = make_url("/preauths/" + this.preauthId + "/cpt_codes/" + this.codes[index]["code"] + "/" + this.codes[index]["preauth_code_id"]);
            }
            if (this.estimate) {
                Object.assign(this.codes[index], data);
                this.closeEditModal(index);
                return;
            } else {
                delete data.allowed_amount;
                const self = this;
                const promise = authenticated_request({
                    method: "put",
                    url: url,
                    data: data,
                }).then(function(response){
                    if (response){
                        self.errorTextModal = "";
                        self.showAlertModal = false;
                        self.$store.commit("setUpdateBilling", true);
                        self.getCodes();
                        self.closeEditModal(index);
                    }
                }).catch(function(error){
                    self.errorTextModal = error;
                    self.showAlertModal = true;
                });
                return promise;
            }
        },
        getResponsibilityLabel(type){
            if(type == 0){
                return "Payer"
            } else if(type == 1){
                return "Patient"
            }
        },
        handleCptCodeAddSubmitted(){
            this.getCodes();
        },
    },
    computed: {
        totalAllowedNumber: function(){
            var total = 0;
            if (this.codes.length > 0){
                for (var i = 0; i < this.codes.length; i++){
                    if (this.codes[i].allowed_amount && this.codes[i].units && this.codes[i].state !== "REJECTED"){
                        total = total + (this.codes[i].units * this.codes[i].allowed_amount);
                    }
                }
            }
            return total;
        },
        totalAllowed: function(){
            return this.totalAllowedNumber.toFixed(2);
        },
        totalChargedNumber: function(){
            var total = 0;
            if (this.codes.length > 0){
                for (var i = 0; i < this.codes.length; i++){
                    if (this.codes[i].unit_charge && this.codes[i].units){
                        total = total + (this.codes[i].units * this.codes[i].unit_charge);
                    }
                }
            }
            return total;
        },
        totalCharged: function(){
            return this.totalChargedNumber.toFixed(2);
        },
        updateCPTCodes: function(){
            return this.$store.state.claims.updateCPTCodes;
        }
    },
    watch:{
        codes: function(){
            this.$emit("codesUpdated", this.codes);
        },
        payerId: function(){
            this.getCodes();
        },
        updateCPTCodes: function() {
            if (this.updateCPTCodes){
                this.getCodes();
                this.$store.commit("setUpdateCPTCodes", false);
            }
        }
    },
    mounted() {
        this.getCodes();
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.no-data-text {
    padding: 16px 0px;
    font-size: 15px;
    font-weight: 200;
    text-align: center;
}
</style>