<template>
<div v-if="insurance" id="modify-insurance">
<b-modal id="edit-insurance" @hide="resetModal" title="Edit Patient Insurance" hide-footer size="xl">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <b-row>
            <b-col>
                <label>Member ID</label>
                <b-form-input v-model="new_insurance.member_id"></b-form-input>
            </b-col>
            <b-col>
                <label>Group No.</label>
                <b-form-input v-model="new_insurance.group_no"></b-form-input>
            </b-col>
            <b-col cols="6">
                <b-row>
                    <b-col>
                        <label>Effective Dates</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-input type="date" v-model="new_insurance.effective_start_date" 
                        :placeholder="new_insurance.effective_start_date || ''" 
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="en"
                        ></b-form-input>
                    </b-col>
                <b-col cols="1.5" style="padding-top:.75rem;">
                    to
                </b-col>
                <b-col>
                    <b-form-input type="date" v-model="new_insurance.effective_end_date" 
                    :placeholder="new_insurance.effective_end_date || ''"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    ></b-form-input>
                </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col>
                <label>Subscriber's name</label>
                <b-form-input v-model="new_insurance.subscriber_name"></b-form-input>
            </b-col>
            <b-col>
                <label>Relationship to subscriber</label>
                <b-form-radio-group 
                v-model="new_insurance.subscriber_relationship"
                style="margin-top:10px;">
                    <b-form-radio value="0">Self</b-form-radio>
                    <b-form-radio value="1">Spouse</b-form-radio>
                    <b-form-radio value="2">Child</b-form-radio>
                    <b-form-radio value="3">Other</b-form-radio>
                </b-form-radio-group>
            </b-col>
            <b-col cols="1">
                <label class="selecter-label">P/S/T</label>
                    <b-form-select
                        v-model="new_insurance.insurance_primacy"
                        :options="[{text: 'P', value: 'PRIMARY'}, {text: 'S', value: 'SECONDARY'}, {text: 'T', value: 'TERTIARY'}, {text: 'U', value: 'UNKNOWN'}]"
                        >/</b-form-select>
            </b-col>
            <b-col cols="2" class="mt-auto">
                <b-form-checkbox v-model="insurance.active_benefits" switch>
                    <div class="selecter-label mt-0">Verified active</div>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row class="" v-if="new_insurance.insurance_primacy === 'PRIMARY'">
            <b-col>
                <label>Co-pay</label>
                <b-input-group prepend="$">
                <b-form-input 
                v-model="new_insurance.copay"
                type="number"
                ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <label>Deductible not met</label>
                <b-input-group prepend="$">
                <b-form-input 
                v-model="new_insurance.deductible"
                type="number"
                ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <label>OOP not met</label>
                <b-input-group prepend="$">
                <b-form-input 
                v-model="new_insurance.out_of_pocket"
                type="number"
                ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <label>Co-insurance</label>
                <b-input-group append="%">
                <b-form-input 
                v-model="new_insurance.patient_responsibility"
                type="number"
                ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col cols="3">
                <label style="margin-top:25px;">Reponsibility type</label>
                <b-form-radio-group
                v-model="new_insurance.patient_responsibility_type">
                    <b-form-radio value="0">Deductible</b-form-radio>
                    <b-form-radio value="1">OOP</b-form-radio>
                </b-form-radio-group>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col style="text-align:center">
                <b-button @click="onSubmit" class="avo-primary-btn">
                    Submit Insurance Changes
                </b-button>
            </b-col>
        </b-row>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'InsuranceEdit',
  props: ['patientId', 'insurance',],
  mixins: [alertsMixin],
  data: function(){
      return {
            new_insurance: {
                member_id: null,
                group_no: null,
                effective_start_date: null,
                effective_end_date: null,
                subscriber_name: null,
                subscriber_relationship: -1,
                active_benefits: false,
                insurance_primacy: 'UNKNOWN',
                copay: null,
                deductible: null,
                out_of_pocket: null,
                patient_responsibility: null,
                patient_responsibility_type: -1,
            },
      }
  },
  methods: {
    clearData(){
        this.new_insurance = Object.assign({}, this.insurance);
        this.hideError();
    },
    validateInsuranceInputs(){
        this.hideError();
        
        if (this.patientId == null){
            this.showError("Unable to find patient id");
            return false;
        }
        
        
        if (this.insurance.payer_id == null){
            this.showError("Unable to find payer id");
            return false;
        }
        
        return true;
    },
    edit_patient_payer(){
        var data = {};
        for (let key in this.new_insurance){
            if (this.insurance[key] != this.new_insurance[key]){
                if ((key != "effective_start_date") && (key != "effective_end_date")){
                    data[key] = this.new_insurance[key];
                }
                else{
                    data[key] = this.new_insurance[key] + "T00:00:00+00:00";
                }
            }
        }
        const url = make_url("/patients/" + this.patientId + "/payers/" + this.insurance.payer_id);
        const self = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: data
        }).then(function(response){
            if (response){
                self.$bvModal.hide('edit-insurance');
                self.$emit('submitted');
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInsuranceInputs()){
            this.edit_patient_payer();
        }
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  watch: {
      insurance: function(){
          this.new_insurance = Object.assign({}, this.insurance);

          if(this.new_insurance.effective_start_date){
              this.new_insurance.effective_start_date = this.new_insurance.effective_start_date.split('T')[0];
          }
          if(this.new_insurance.effective_end_date){
              this.new_insurance.effective_end_date = this.new_insurance.effective_end_date.split('T')[0];
          }
      }
  }
}
</script>
