<template>
<div id="add-cpt-code">
<b-modal id="new-cpt-code" @hide="resetModal" title="New CPT Code" hide-footer size="md">
<b-form @submit="onSubmit" class="p-3">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row>
        <b-col>
            <b-form-group
            id="code"
            label="Code:">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by code or description" 
                    label="code"
                    :custom-label="codeWithDescription"
                    v-model="selected_code"
                    track-by="code"
                    :options="selectableCPTCodes"
                    :loading="cptLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debounceCPTCodes"
                    @select="debounceGetFeeSchedule"
                    :showNoOptions="true"
                    :allow-empty="false">
                    <template slot="noResult">
                        No codes matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a code or description...
                    </template>
                </multiselect>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row v-if="(!newPreauth) && claimId">
        <b-col>
            <b-form-group
            id="pointers"
            label="Pointers:">
                <multiselect
                    v-model="pointers"
                    :hide-selected="true"
                    :options="selectablePointers"
                    :multiple="true"
                    :show-labels="false"
                    :searchable="false"
                    label="name"
                    track-by="value"
                    placeholder="Select pointers">
                </multiselect>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="modifiers"
            label="Modifiers:">
            <b-input-group>
                <b-form-input @change="debounceGetFeeSchedule" class="mr-2" id="modifier-1" type="text" v-model="modifiers[0]" placeholder="XX"></b-form-input>
                <b-form-input @change="debounceGetFeeSchedule" class="mr-2" id="modifier-2" type="text" v-model="modifiers[1]" placeholder="XX"></b-form-input>
                <b-form-input @change="debounceGetFeeSchedule" class="mr-2" id="modifier-3" type="text" v-model="modifiers[2]" placeholder="XX"></b-form-input>
                <b-form-input @change="debounceGetFeeSchedule" id="modifier-4" type="text" v-model="modifiers[3]" placeholder="XX"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="pos"
            label="POS:">
            <b-input-group>
                <b-form-input @change="debounceGetFeeSchedule" type="number" v-model="pos"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="tos"
            label="TOS:">
            <b-input-group>
                <b-form-input @change="debounceGetFeeSchedule" type="number" v-model="tos"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
            id="units"
            label="Units:">
            <b-input-group>
                <b-form-input type="number" min="0" v-model="units"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="unit-charge"
            label="Unit charge:">
            <b-input-group prepend="$">
                <b-form-input type="number" min="0" v-model="unit_charge" :disabled="unit_charge_loading"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row v-if="estimate">
        <b-col>
            <b-form-group
            id="allowed-amount"
            label="Unit allowed amount:">
            <b-input-group prepend="$">
                <b-form-input type="number" min="0" v-model="allowed_amount" :disabled="allowed_amount_loading"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col class="mt-2" style="text-align:right">
            <b-button @click="onSubmit" class="avo-primary-btn">
                Add CPT Code
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request, debounce, alertsMixin } from '../../common.js'

export default {
  name: 'CptCodeAdd',
  props: {
      claimId: {
          type: Number,
          default: null
      },
      preauthId: {
            type: Number,
            default: null
      },
      practiceId: {
          type: Number,
          default: null
      },
      currentCodes: {
          type: Array,
          default: null
      }, 
      selectablePointers: {
          type: Array,
          default: null
      }, 
      newPreauth: {
            type: Boolean,
            default: false
      },
      estimate: {
          type: Boolean,
          default: false
      }
  },
  mixins: [alertsMixin],
  components: {
      Multiselect
  },
  data: function(){
      return {
            selected_code: null,

            units: null,
            unit_charge: null,
            unit_charge_loading: false,
            allowed_amount: null,
            allowed_amount_loading: false,
            pos: 11,
            tos: 2,
            pointers: [],

            final_code: {
                code: null,
                modifiers: null,
                short: null,
                medium: null,
                units: null,
                unit_charge: null,
                allowed_amount: null,
                pos: 11,
                tos: 2,
                pointers: []
            },

            selectableCPTCodes: [],
            searchText: "",
            cptLoading: false,
            modifiers: ["", "", "", ""],
      }
  },
  methods: {
    clearCode(){
        this.selected_code = null

        this.units = null,
        this.unit_charge = null,
        this.allowed_amount = null,
        this.allowed_amount_loading = false,
        this.pos = 11,
        this.tos = 2,
        this.pointers = [],
        
        this.final_code = {
                code: null,
                modifiers: null,
                medium: null,
                short: null,
                unit_charge: null,
                allowed_amount: null,
                units: null,
                pos: 11,
                tos: 2,
                pointers: []
        }
        
        this.modifiers = ["", "", "", ""]
        this.hideError();
    },
    finalizeAndValidateInputs(){
        this.hideError();
        
        this.final_code.code = this.selected_code.code;
        this.final_code.short = this.selected_code.short;
        this.final_code.medium = this.selected_code.medium;

        this.final_code.pointers = this.pointers;
        this.final_code.units = this.units;
        this.final_code.unit_charge = this.unit_charge;
        this.final_code.allowed_amount = this.allowed_amount;
        
        //add code modifiers to dict
        this.final_code.modifiers = [];
        for (var i = 0; i < 4; i++){
            if ((this.modifiers[i] != null) && (this.modifiers[i] != "")){
                //check that no duplicate values exist in array
                for (var j = 0; j < this.final_code.modifiers.length; j++){
                    if (this.modifiers[i] == this.final_code.modifiers[j]){
                        this.showError("Modifiers must be unique values");
                        return false;
                    }
                }
                this.final_code.modifiers.push(this.modifiers[i]);
            }
        }
        
        //validate inputs
        if ((this.final_code.code) == null || (this.final_code.code == "")){
            this.showError("Missing field: code");
            return false;
        }
        else if (this.final_code.code.length != 5){
            this.showError("CPT codes must have length of 5");
            return false;
        }
        for (i = 0; i < 4; i++){
            if (!((this.final_code.modifiers[i]) == null || (this.final_code.modifiers[i] == ""))
                && (this.final_code.modifiers[i].length != 2)){
                this.showError("Modifiers must have length of 2");
                return false;
            }
        }
        
        return true
    },
    addCodeToClaim(){
        let url;
        if (this.preauthId !== null){
            url = make_url('/preauths/' + this.preauthId + '/cpt_codes');
        }
        else if (this.claimId !== null){
            url = make_url('/claims/' + this.claimId + '/cpt_codes');
        }
        var data = {
            "code": this.final_code.code,
            "unit_charge": (this.final_code.unit_charge || "0.00"),
            "units": (this.final_code.units || 0),
            "pos": (this.final_code.pos || 11),
            "tos" : (this.final_code.tos || 2),
            "pointers": (this.final_code.pointers ? this.final_code.pointers.map(pointer => pointer.value) : []),
        }
        if (this.final_code.modifiers.length > 0){
            data["modifiers"] = this.final_code.modifiers;
        }
        if (this.estimate) {
            data["allowed_amount"] = this.final_code.allowed_amount;
            data["modifiers"] = this.final_code.modifiers;
            data["medium"] = this.final_code.medium;
            data["short"] = this.final_code.short;
            this.$emit('add-cpt-code', data);
            this.$bvModal.hide('new-cpt-code');
            return;
        } else {
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: data
        }).then(function(response){
            if (response){
                self.$bvModal.hide('new-cpt-code');
                self.$emit('submitted');
                self.$store.commit("setUpdateBilling", true);
                self.$store.commit("refreshActivityLog"); 
            }
        }).catch(function(error){
            console.log(error.response)
            self.showError(error);
        });
        return promise;
        }
    },
    onSubmit(){
        if (this.finalizeAndValidateInputs()){
            if(this.newPreauth){
                console.log("storing for preauth")
                this.$store.commit("preauths/storeCPTCode", this.final_code);
                this.$bvModal.hide('new-cpt-code');
            } else {
                this.addCodeToClaim();
            }
        }
    },
    resetModal(){
        this.hideError();
        this.clearCode();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
    codeWithDescription(code){
        if (code.code && code.short){
            return (code.code + ": " + code.short)
        }
        return ""
    },
    searchChange: function(searchQuery) {
        this.cptLoading = true;
        this.searchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.CPTAutocomplete();
        } else {
            this.cptLoading = false;
        }
        
    },
    CPTAutocomplete() {
        var vm = this;
        const url = make_url("/reports/codes/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.searchText,
                limit: 10
            }
        }).then(function(response){
            vm.cptLoading = false;
            const codes = response.data.codes;
            vm.selectableCPTCodes = codes || [];
            return codes || [];
        }).catch(function(errors){
            console.log(errors.response)
            vm.showError(errors);
        });
    },
    getFeeSchedule(){
        // TODO: this should be cleaned up, we should be able to just use practiceId in all cases
        if ((this.claimId || (this.payer_id && (this.practiceId || this.preauthId))) 
                && this.selected_code.code && this.pos && this.tos){
            const vm = this;
            vm.unit_charge_loading = true;
            vm.allowed_amount_loading = true;
            const url_prefix = (() => {
                if (this.claimId != null) {
                    return "/claims/".concat(this.claimId);
                } else if (this.practiceId != null) {
                    return "/practices/".concat(this.practiceId);
                } else if (this.preauthId != null) {
                    return "/preauths/".concat(this.preauthId);
                }
            })();
            const url = make_url(url_prefix.concat("/cpt_codes/", this.selected_code.code, "/unit_charge")).toString();
            const params = {
                    modifiers: this.modifiers.join(","),
                    pos: this.pos,
                    tos: this.tos,
                    ...(this.claimId == null && this.preauthId == null 
                        && { payer_id: this.payer_id })
                };
            authenticated_request({
                method: "get",
                url: url,
                params: params,
            }).then(function(response){
                vm.unit_charge = response.data?.charge_fee;
                vm.allowed_amount = response.data?.allowed_amount;
            }).catch(function(errors){
                vm.showError(errors);
            }).finally(function() {
                vm.unit_charge_loading = false;
                vm.allowed_amount_loading = false;
            });
        }
    }
  },
  created() {
      this.debounceCPTCodes = debounce(this.searchChange, 300);
      this.debounceGetFeeSchedule = debounce(this.getFeeSchedule, 300);
  },
  mounted: function() {
  },
  computed: {
     primaryInsurance: function() {
         if (this.$store.getters['preauths/insuranceModulePrimaryInsurance'] != null) {
             return this.$store.getters['preauths/insuranceModulePrimaryInsurance'];
         }
         return null
     },
     payer_id: function() {
         return this.primaryInsurance?.payer_id;
     },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5;
}

</style>